import React from "react";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

import BackgroundImage from "gatsby-background-image";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "images/bg-2.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor="#040e18"
        >
          <div className=" bg-gray-900 bg-opacity-50 w-full h-full">
            {children}
          </div>
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`;

BackgroundSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
export default StyledBackgroundSection;
