import React from 'react';
import { Link } from 'gatsby';

const menuItems = [
  {
    title: 'Сведения об образовательной организации',
    link: '/MainInformation',
  },
  {
    title: 'Абитуриентам',
    link: '/ForApplicants',
  },
  {
    title: 'Студентам',
    link: '/ForStudents',
  },
  {
    title: 'Родителям',
    link: '/ForParents',
  },
  {
    title: 'Педагогам',
    link: '/ForTeachers',
  },

  {
    title: 'Структура и органы управления образовательной организации',
    link: '/Structure',
  },
  { title: 'Документы', link: '/Documents' },
  { title: 'Образование', link: '/Education' },
  { title: 'Платные образовательные услуги', link: '/PaidServices' },
  { title: 'Образовательные стандарты', link: '/EduStandarts' },
  {
    title: 'Руководство. Педагогический (научно-педагогический) состав',
    link: '/Teachers',
  },
  {
    title:
      'Материально-техническое обеспечение и оснащенность образовательного процесса',
    link: '/Materials',
  },
  {
    title: 'Стипендии и иные виды материальной поддержки',
    link: '/Scolarships',
  },
  {
    title: 'Архив',
    link: '/Archive',
  },
  {
    title: 'Финансово-хозяйственная деятельность',
    link: '/Finances',
  },
  {
    title: 'Вакантные места для приема (перевода)',
    link: '/Vacancies',
  },
  {
    title: 'Доступная среда',
    link: '/Area',
  },
  {
    title: 'Международное сотрудничество',
    link: '/International',
  },
];

const SiteMap = () => {
  return (
    <div>
      <h2 className='text-4xl my-4 uppercase text-center'>Карта Сайта</h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
        {menuItems.map((item) => {
          return (
            <Link
              to={item.link}
              key={item.title}
              className='main-color text-white p-4 m-4 uppercase text-center
              text-xs rounded shadow hover:bg-green-600 hover:shadow-xl 
              transform hover:scale-105 transition ease-in-out duration-700 
              flex items-center justify-center'
            >
              {item.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SiteMap;
