/* eslint-disable react/no-unused-prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import axios from "axios";

import Marquee from "react-fast-marquee";
// import Img from "gatsby-image";

import Layout from "../components/layoutHero";
import SEO from "../components/seo";
import SiteMap from "../components/SiteMap";
import { graphql } from "gatsby";
// import catAndHumanIllustration from '../images/cat-and-human-illustration.svg';

const defaultAdvText =
  'ЧАСТНОЕ ОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ДОПОЛНИТЕЛЬНОГО ПРОФЕССИОНАЛЬНОГО ОБРАЗОВАНИЯ "Профф"...    ';

function IndexPage({ data }) {
  const [advText, setAdvText] = useState(defaultAdvText);

  useEffect(() => {
    const getPods = async () => {
      const response = await axios.get(
        "https://wp-api.proff86.ru/wp-json/wp/v2/texts?per_page=100&slug=marquee"
      );
      // eslint-disable-next-line no-console
      console.log("@@@", response.data);
      setAdvText(response.data[0]?.text || defaultAdvText);
    };
    getPods();
  }, []);

  // eslint-disable-next-line no-console
  console.log("adel", data);

  return (
    <Layout>
      <SEO keywords={["ЧОУ ДПО Профф", "главная"]} title="Home" />

      <Marquee className=" -mt-6 mb-2" speed={80} pauseOnHover>
        <h2 className="text-xl font-semibold text-green-900">{advText}</h2>
      </Marquee>

      {/* <section className="text-center flex">
        <div className="w-1/2 bg-purple-100 flex items-center p-4  rounded shadow-lg">
          <p className="px-2 text-left text-2xl">
            <span className="font-bold text-center">
              Дорогие наши коллеги Людмила Петровна! Евгений Валерьевич! Ирина
              Ивановна! Поздравляем Вас с Вашим днем. Днем Вашего рождения!
            </span>
            <br />
            Желаем премии большие получать, <br />
            Работать так, чтобы не уставать.
            <br />
            Свой отпуск за границей проводить.
            <br />
            А на больничные совсем не уходить.
            <br />
            В семье желаем, чтоб идиллия была,
            <br />
            В делах удача лишь тебя ждала.
            <br />
            Свой каждый день с улыбкою встречать
            <br />
            И не бояться в жизни рисковать.
            <br />
          </p>
        </div>
        <div className="w-1/2 bg-white p-4  rounded shadow-lg">
          <Img fluid={data.work.childImageSharp.fluid} alt="Поздравление" />
        </div>
      </section> */}
      {/* <section className="text-center flex">
          <div className="w-full bg-white p-4  rounded shadow-lg">
            <Img fluid={data.teachers.childImageSharp.fluid} alt="Поздравление" />
          </div>
        </section>
        <section className="text-center flex">
          <div className="w-1/2 bg-purple-100 flex items-center p-4  rounded shadow-lg">
            <p className="px-2 text-center text-2xl"></p>
          </div>
          <div className="w-1/2 bg-white p-4  rounded shadow-lg">
            <Img
              fluid={data.oldPeople.childImageSharp.fluid}
              alt="Поздравление"
            />
          </div>
        </section>
        <section className="text-center flex">
          <div className="w-1/2 bg-white p-4  rounded shadow-lg">
            <Img
              fluid={data.vospitatel.childImageSharp.fluid}
              alt="Поздравление"
            />
          </div>
          <div className="w-1/2 bg-purple-100 flex items-center p-4  rounded shadow-lg">
            <p className="px-2 text-center text-2xl">
              Всех, кто по зову сердца выбрал ответственную, благородную, сложную,
              но интересную работу с дошколятами, поздравляем с профессиональным
              праздником — Днём воспитателя и всех дошкольных работников! За Ваш
              неоценимый вклад в обучение и воспитание юного поколения хотим Вас
              поблагодарить и пожелать доброго здоровья, уважения, материального
              благополучия, любви и признания. Пусть каждый день приносит новые
              яркие впечатления, а жизнь состоит не только из упорного труда, но и
              из насыщенного отдыха. С уважением, ЧОУ ДПО &quot;ПРОФФ&quot;
            </p>
          </div>
        </section> */}

      {/* <section className="text-center flex">
        <div className="w-1/2 bg-purple-100 flex items-center p-4  rounded shadow-lg">
          <p className="p-2 text-center text-2xl">
            Поздравляем Всех с началом нового учебного года! Хотим пожелать
            никогда не переставать верить в себя, постоянно стремиться к новым
            успехам и достижениям, всегда добиваться поставленных целей и не
            утратить интереса ко всему новому и неизвестному. Пусть в вашей
            жизни будет возможность учиться и обретать знания не только в
            школьные годы, но и в будущем, в течение всей жизни! Знания делают
            человека сильнее, мудрее, добрее и помогают открывать новые истины!
            С уважением, ЧОУ ДПО &quot;ПРОФФ&quot;
          </p>
        </div>
        <div className="w-1/2 bg-white p-4  rounded shadow-lg">
          <Img
            fluid={data.firstSept.childImageSharp.fluid}
            alt="С 1-м Сентября"
          />
        </div>
      </section> */}

      {/* <section className="text-center flex">
        <div className="w-1/2 bg-white p-4  rounded shadow-lg">
          <Img fluid={data.congrats.childImageSharp.fluid} alt="Поздравление" />
        </div>
        <div className="w-1/2 bg-purple-100 flex items-center p-4  rounded shadow-lg">
          <p className="p-2 text-center text-2xl">
            Коллектив ЧОУ ДПО &quot;ПРОФФ&quot; поздравляет с днем рождения
            специалиста отдела организации обучения Чеботарь Алию Иршатовну.
            Желает здоровья, успехов и благополучия!
          </p>
        </div>
      </section> */}

      <section className="text-center">
        <div className="w-full bg-white p-4 rounded shadow-lg">
          <p className=" text-left text-blue-500">
            Исх. № 311 от «02» апреля 2020 г.
          </p>
          <h2 className=" text-2xl my-4 text-center">
            ИНФОРМАЦИЯ ДЛЯ РУКОВОДИТЕЛЕЙ ОРГАНИЗАЦИЙ
          </h2>
          <p className="p-2 text-left text-lg">
            Частное образовательное учреждение &quot;ПРОФФ&quot; проводит
            дистанционное обучение без отрыва от производства, по
            дополнительному обучению и основным профессиям. Вы сможете комфортно
            пройти обучение на официальном сайте учреждения с полным
            сопровождением высококвалифицированных специалистов, сдать
            тестирование, не выходя из дома или не отрываясь от рабочего
            процесса.
          </p>
          <hr />
          <ul className="my-2 text-lg">
            <li>
              По всем вопросам обращаться по телефону{" "}
              <strong>8 (932)-257-52-63</strong>
            </li>
            <li>
              Для электронных писем{" "}
              <strong className="text-blue-500 tracking-wider">
                proff461444@mail.ru
              </strong>
            </li>
            <li>
              Наш сайт <strong>http://proff86.ru/</strong>{" "}
            </li>
          </ul>
          <h4 className="text-lg text-green-700">
            С уважением к Вам и Вашему бизнесу, ЧОУ ДПО ПРОФФ.
          </h4>
        </div>
        <SiteMap />
      </section>
    </Layout>
  );
}

export const query = graphql`
  query MyQuery {
    congrats: file(relativePath: { eq: "images/congrats.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    firstSept: file(relativePath: { eq: "images/1stSeptember.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vospitatel: file(relativePath: { eq: "images/congrats_vospitatel.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    oldPeople: file(relativePath: { eq: "images/congrats_old_people.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teachers: file(relativePath: { eq: "images/congrats_teachers_day.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    work: file(relativePath: { eq: "images/congrats_work.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 1700, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
