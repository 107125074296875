/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// import Slider from "../components/slider";

import HeroBG from './heroBG';

const defaultHeroText = 'СОВРЕМЕННЫЕ МЕТОДИКИ ПРОФЕССИОНАЛЬНОГО ОБУЧЕНИЯ.';

const Hero = () => {
    const [heroText, setHeroText] = useState(defaultHeroText);

    useEffect(() => {
        const getPods = async () => {
            const response = await axios.get(
                'https://wp-api.proff86.ru/wp-json/wp/v2/texts?per_page=100&slug=hero_left'
            );
            // eslint-disable-next-line no-console
            // console.log("@@@", response.data);
            setHeroText(response.data[0]?.text || defaultHeroText);
        };
        getPods();
    }, []);
    return (
        <HeroBG className=" flex flex-col items-center justify-center h-24rem overflow-hidden bg-cover shadow-lg">
            <div className="md:flex  items-center justify-center  h-full  mx-auto">
                <div className=" w-20rem m-auto md:m-0 md:w-1/2 md:flex  items-center justify-center relative">
                    {/* <Slider /> */}
                    <h2 className="text-2xl lg:text-5xl md:text-4xl sm:text-3xl  text-center md:text-left my-4 text-yellow-200 md:leading-tight lg:leading-tight font-bold italic">
                        {heroText || defaultHeroText}
                    </h2>
                </div>
                <div className="md:w-1/2 max-w-lg m-auto md:m-0 flex flex-col items-center justify-center">
                    <h1 className="text-4xl text-white font-bold">
                        &quot;Профф&quot;
                    </h1>
                    <p className="text-center text-white text-lg font-bold">
                        ЧАСТНОЕ ОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ ДОПОЛНИТЕЛЬНОГО
                        ПРОФЕССИОНАЛЬНОГО ОБРАЗОВАНИЯ
                    </p>
                    <a
                        href="http://moodle.proffadmin.beget.tech"
                        target="_blank"
                        rel="noreferrer"
                        className="px-4 py-2  text-3xl  border  border-green-700 text-gray-500 hover:bg-gray-500 hover:bg-opacity-25
            hover:text-gray-100 mt-6 rounded uppercase"
                    >
                        онлайн-обучение
                    </a>
                </div>
            </div>
        </HeroBG>
    );
};

export default React.memo(Hero);
