import PropTypes from 'prop-types';
import React from 'react';

import Header from './header';
import Footer from './Footer';
import Hero from './Hero';

const Layout = ({ children }) => {
  return (
    <div className='flex flex-col min-h-screen font-sans bg-gray-200 text-gray-900 '>
      <Header />
      <Hero />

      <main className=' flex-1 w-full max-w-screen-xl  px-4 py-8 mx-auto md:px-8 lg:px-16 md:py-8'>
        {children}
        {/* <a
          href='#'
          className='bvi-open bg-red-700 text-center uppercase z-50 text-white rounded p-2 w-56 tracking-wider fixed right-10 bottom-10 '
          title='Версия сайта для слабовидящих'
        >
          Версия сайта для слабовидящих
        </a> */}

        {/* <a
          id='specialButton'
          href='#'
          // className='bvi-open bg-red-700 text-center uppercase z-50 text-white rounded p-2 w-56 tracking-wider fixed right-10 bottom-10 '
        >
          <img
            src='https://lidrekon.ru/images/special.png'
            alt='ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ'
            title='ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ'
          />
        </a> */}
      </main>

      {/* <div className='bvi-show'>Текст покажется при включении плагина.</div> */}

      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(Layout);
